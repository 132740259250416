import React, { Component } from 'react';
import styled from 'styled-components'

class TwitterButton extends Component {
	render(){
		return(
			<Icon width="25" height="25" ><path d="M14.095 10.316 22.286 1h-1.94L13.23 9.088 7.551 1H1l8.59 12.231L1 23h1.94l7.51-8.543L16.45 23H23l-8.905-12.684zm-2.658 3.022-.872-1.218L3.64 2.432h2.98l5.59 7.821.869 1.219 7.265 10.166h-2.982l-5.926-8.3z"/></Icon>
		)
	}
}

const Icon = styled.svg`
	stroke:#000;
	position: absolute;
	left: 5px;
	top: 5px;
	width: 25px !important;
	height: 25px !important;
	-webkit-transform: scale(.8);
	-ms-transform: scale(.8);
	transform: scale(.65);
`

export default TwitterButton;


